import {SbBlokData, storyblokEditable, useStoryblokApi} from "@storyblok/react";
import {RecaptchaProvider} from "../internal/RecaptchaProvider";
import {useRecaptchaVerify} from "../hooks/UseRecaptchaVerify";
import {toast} from "react-toastify";
import {useRouter} from "next/router";

import React, {useEffect, useState} from "react";

import {getConversionData} from "../../utils/getConversionData";

import {transformImage} from "../../utils/StoryblokService";
import {richTextResolver} from "@storyblok/richtext";

type TProps = {
    noBlok: boolean,
    blok: {
        form_heading: any,
        form_intro: string,
        icon: any,
    } & SbBlokData
}

const WrappedContactForm = (props: TProps) => {
    const {blok} = props;

    const router = useRouter();

    const {handleReCaptchaVerify} = useRecaptchaVerify();

    const [isSubmitting, setIsSubmitting] = useState(false);


    const [formData, setFormData] = useState({
        firstName: '',
        email: '',
        message: '',
        company: '',
        howdidyouhear: '',
    });

    const storyblokApi = useStoryblokApi()

    const submitForm = (e: any) => {
        e.preventDefault();
        setIsSubmitting(true)

        const formDataObj = new FormData(e.target);
        if (formDataObj.get("howdidyouhear") !== "") {
            // Form submission is spam
            return;
        }

        if (formData.howdidyouhear.length > 0) {
            return;
        }

        if (formData.message.length < 40 || formData.message.length > 2500) {
            toast('Please enter a message with at least 50 characters.', {
                type: 'error',
                position: 'bottom-right'
            });
            setIsSubmitting(false)
            return;
        }

        // handleReCaptchaVerify().then(token => {
        type P = keyof typeof formData;
        for (let key in formData) {
            if (key !== 'company' && key !== 'howdidyouhear' && formData[key as P]?.length === 0) {
                toast('Please fill out all fields.', {
                    type: 'error',
                    position: 'bottom-right'
                });
                setIsSubmitting(false)
                return;
            }
        }

        fetch("/api/contactmail", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                ...formData,
                ...getConversionData(),
                // recaptcha: token
            })
        }).then((res: any) => {
            if (res.status !== 200) {
                // Error
                toast('Sorry, something went wrong. Please try again later.', {
                    type: 'error',
                    position: 'bottom-right'
                });

            } else {
                // @ts-ignore
                // window.gtag('event', 'conversion', {'send_to': 'AW-1001820813/RhqJCJ3fs_sBEI2l2t0D'});

                window.gtag('event', 'conversion', {'send_to': 'AW-10838573329/c44VCKyd954DEJGCnrAo'});
                // @ts-ignore
                // window.gtag('event', 'contact_form_success');
                // toast('Thank you for your message. We will get back to you as soon as possible.', {
                //     type: 'success',
                //     position: 'bottom-right'
                // });

                fetch("/api/hubspot", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        ...formData,
                        // recaptcha: token
                    })
                }).then((res: any) => {
                    router.push('/success')
                })
                // Redirect to thank you page

            }
        });

        // }        )

    }

    const {render} = richTextResolver()

    return (
        <div className="contact-form-tiny "  {...storyblokEditable(blok)}>

            <main className="overflow-hidden">
                {/* Header */}
                <div className="bg-white pt-8">
                    <div className="">
                        <div
                            className="relative z-10 mx-auto max-w-7xl text-center pl-4 pr-8 px-4 sm:px-6 lg:px-8 flex items-center justify-center gap-4">

                            {blok?.icon?.filename && <img
                                src={transformImage(blok.icon.filename, "512x512")}
                                className={'w-14 h-14'}
                                alt={blok.icon.alt}
                            />}
                            {!props.noBlok && !blok?.icon?.filename &&
                                <img src={'/images/icons/30app.svg'} alt={'robot'} className={'w-14 h-14'}/>}

                            <div>
                                {/*<h1 className="text-xl sm:text-2xl lg:text-2xl font-bold tracking-tight text-warm-gray-900 ">*/}
                                {/*    {blok.form_heading ? blok.form_heading : 'Fast Kick-Off. Quick Turnaround.'}*/}
                                {/*</h1>*/}

                                {!props.noBlok &&
                                    <div className={'text-gray-800 text-lg lg:text-2xl contact-form-heading'}>
                                        <div dangerouslySetInnerHTML={{
                                            __html: render(blok.form_heading) as string
                                        }}></div>
                                    </div>}

                            </div>
                        </div>

                        <div className="mt-6 max-w-4xl lg:text-xl text-warm-gray-500 rich-text mx-auto"
                        >
                            <p className={'max-w-[450px] mx-auto text-center'}>
                                {blok?.form_intro ? blok.form_intro : <>We are ready to discuss your project.
                                    <br/>
                                    Free consultation. No strings attached.</>}
                            </p>
                            <p className={'text-center'}>
                                Do you prefer to call us? <a className={'link'}
                                                             href={'tel:+19495412305'}>(949) 541-2305
                            </a>
                            </p>
                        </div>

                    </div>
                </div>

                {/* Contact section */}
                <section className="relative bg-white pb-8" aria-labelledby="contact-heading">
                    <div className="absolute h-1/2 w-full" aria-hidden="true"/>

                    <div className="mx-auto max-w-3xl px-4 md:px-6 lg:px-8">
                        <div className="relative bg-gray-50 shadow-xl">
                            <h2 id="contact-heading" className="sr-only">
                                Contact us
                            </h2>

                            <div className="">

                                {/* Contact form */}
                                <div className="px-6 sm:px-10 lg:col-span-2 xl:px-12 pt-2 pb-6">
                                    <form action="#"
                                          onSubmit={submitForm}
                                          className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
                                        <div>
                                            <label htmlFor="first-name"
                                                   className="block text-sm font-medium text-warm-gray-900">
                                                Name
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    value={formData.firstName}
                                                    onChange={e => setFormData({
                                                        ...formData,
                                                        firstName: e.target.value
                                                    })}
                                                    type="text"
                                                    name="first-name"
                                                    id="first-name"
                                                    autoComplete="given-name"
                                                    className="block w-full rounded-md border border-warm-gray-200 py-3 px-4 text-warm-gray-900 shadow-sm focus:border-amber-300 focus:ring-amber-300 hover:border-amber-300 focus:outline-amber-300"
                                                />
                                            </div>
                                        </div>

                                        <div>
                                            <label htmlFor="email"
                                                   className="block text-sm font-medium text-warm-gray-900">
                                                Email
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    value={formData.email}
                                                    onChange={e => setFormData({
                                                        ...formData,
                                                        email: e.target.value
                                                    })}
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    autoComplete="email"
                                                    className="block w-full rounded-md border border-warm-gray-200  py-3 px-4 text-warm-gray-900 shadow-sm focus:border-amber-300 focus:ring-amber-300 hover:border-amber-300 focus:outline-amber-300"
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <label htmlFor="last-name"
                                                   className="block text-sm font-medium text-warm-gray-900">
                                                Company
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    value={formData.company}
                                                    onChange={e => setFormData({
                                                        ...formData,
                                                        company: e.target.value
                                                    })}
                                                    type="text"
                                                    name="company"
                                                    id="company"
                                                    autoComplete="family-name"
                                                    className="block w-full rounded-md border border-warm-gray-200  py-3 px-4 text-warm-gray-900 shadow-sm focus:border-amber-300 focus:ring-amber-300 hover:border-amber-300 focus:outline-amber-300"
                                                />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2">
                                            <div className="flex justify-between">
                                                <label htmlFor="message"
                                                       className="block text-sm font-medium text-warm-gray-900">
                                                    Message
                                                </label>
                                                <span id="message-max" className="text-sm text-warm-gray-500">
                          Max. 500 characters
                        </span>
                                            </div>
                                            <div className="mt-1">
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={e => setFormData({...formData, message: e.target.value})}
                            rows={4}
                            className="block w-full rounded-md border border-warm-gray-200  py-3 px-4 text-warm-gray-900 shadow-sm focus:border-amber-300 focus:ring-amber-300 hover:border-amber-300 focus:outline-amber-300"
                            aria-describedby="message-max"
                        />
                                            </div>
                                        </div>
                                        <div className="sm:col-span-2 sm:flex sm:justify-end">
                                            <input name={'howdidyouhear'} id={'howdidyouhear'} type={'text'}
                                                   style={{
                                                       'visibility': 'hidden',
                                                       'zIndex': '-10',
                                                       'position': 'absolute'
                                                   }}
                                                   value={formData.howdidyouhear}
                                                   onChange={e => setFormData({
                                                       ...formData,
                                                       howdidyouhear: e.target.value
                                                   })}
                                            />

                                            <input
                                                type="submit"

                                                className={"mt-2 hidden lg:inline-flex w-full items-center justify-center rounded-md border border-transparent bg-secondary px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-amber-300 focus:ring-offset-2 sm:w-auto " + ((isSubmitting) ? "cursor-not-allowed opacity-50" : "")}
                                                value={'Submit & Get a Response in 24 Hours'}
                                            />

                                            <input
                                                type="submit"

                                                className={"mt-2 lg:hidden w-full items-center justify-center rounded-md border border-transparent bg-secondary px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-amber-300 focus:ring-offset-2 sm:w-auto " + ((isSubmitting) ? "cursor-not-allowed opacity-50" : "")}
                                                value={'Get a Response in 24 Hours.'}
                                            />


                                        </div>

                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>

        </div>
    )

}

export default function ContactFormTiny(props: any) {
    return <RecaptchaProvider>
        <WrappedContactForm {...props} />
    </RecaptchaProvider>
}
