import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {PropsWithChildren} from "react";

export const RecaptchaProvider = (props: PropsWithChildren) => {
    // return (
    //     <GoogleReCaptchaProvider reCaptchaKey="6LdtR3geAAAAAIKB9zoX7p28dyX0zwosOPjOdyUE">
    //         {props.children}
    //     </GoogleReCaptchaProvider>
    // );

    return <>
        {props.children}
    </>
};