import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useCallback} from "react";

export const useRecaptchaVerify = () => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
        }

        return await executeRecaptcha('contactForm');
        // Do whatever you want with the token
    }, [executeRecaptcha]);

    return {handleReCaptchaVerify}
}
